import {Injectable} from '@angular/core'

export const ALERT_DURATION = 3000

interface AlertOptions {
  id?: string;
  type?: 'primary' | 'danger' | 'warning';
  message?: string;
  hiding?: boolean;
  undo?: () => void;
}

@Injectable({
  providedIn: 'root',
})
export class AlertService {

  private _alerts: AlertOptions[] = []

  private show(options: AlertOptions): void {
    this.alerts.forEach(a => this.hide(a.id))
    options.id = Math.random().toString()
    this._alerts.push(options)
    setTimeout(() => this.hide(options.id), ALERT_DURATION)
  }

  private remove(id: string): void {
    const index = this.alerts.findIndex(a => a.id === id)
    if (index !== -1) {
      this._alerts.splice(index, 1)
    }
  }

  hide(id: string): void {
    const index = this.alerts.findIndex(a => a.id === id)
    if (index === -1 || this._alerts[index].hiding) {
      return
    }

    this._alerts[index].hiding = true
    setTimeout(() => this.remove(id), 250)
  }

  showInfo(message: string): void {
    this.show({type: 'primary', message})
  }

  showWarning(message: string): void {
    this.show({type: 'warning', message})
  }

  showError(message: string): void {
    this.show({type: 'danger', message})
  }

  get alerts(): AlertOptions[] {
    return this._alerts
  }
}
